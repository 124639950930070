enum ANALYTICS_BASIC_PROFILE_TYPES {
  'monolith_style' = 'monolith_style'
}

enum ApplicationPlatformGA4 {
  ANDROID = 'mobile_app_android',
  IOS     = 'mobile_app_ios',
  WEBSITE = 'website'
}


// Setup the analytics cookie names
enum ANALYTICS_COOKIE_NAMES {
  'sjAnalytics_a'   = 'sjAnalytics_a',
  'sjAnalytics_s1'  = 'sjAnalytics_s1',
  'sjAnalytics_s2'  = 'sjAnalytics_s2',
  'sjAnalytics_s3'  = 'sjAnalytics_s3',
  'sjAnalytics_s4'  = 'sjAnalytics_s4',
  'sjAnalytics_s5'  = 'sjAnalytics_s5',
  'sjAnalytics_c'   = 'sjAnalytics_c',
  'sjAnalytics_rid' = 'sjAnalytics_rid',
}

enum ANALYTICS_COOKIE_VALUE {
  'true' = 'true',
}

// Setup the event type values
enum ANALYTICS_EVENT_TYPE {
  'survey'           = 'survey',
  'questionnaire'    = 'questionnaire',
  'transition'       = 'transition',
  'redemption'       = 'redemption',
  'prompt'           = 'prompt',
  'contact_support'  = 'contact_support',
  'ste_install'      = 'ste_install',
  'ste_uninstall'    = 'ste_uninstall',
  'referral'         = 'referral',
  'email'            = 'email',
  'login'            = 'login',
  'logout'           = 'logout',
  'ste_instructions' = 'ste_instructions',
  'other'            = 'other',
  'verification'     = 'verification',
}

// Setup the event type values
enum ANALYTICS_EVENT_SUB_TYPE {
  'survey_featured'                         = 'survey_featured',
  'survey_list'                             = 'survey_list',
  'survey_click'                            = 'survey_click',
  'survey_load'                             = 'survey_load',
  'survey_load_cache'                       = 'survey_load_cache',
  'survey_sort'                             = 'survey_sort',
  'survey_email_click'                      = 'survey_email_click',
  'survey_recommended'                      = 'survey_recommended',
  'pulse_desktop'                           = 'pulse_desktop',
  'pulse_extension'                         = 'pulse_extension',
  'paypal'                                  = 'paypal',
  'bank_transfer'                           = 'bank_transfer',
  'virtual_incentive'                       = 'virtual_incentive',
  'survey_medley'                           = 'survey_medley',
  'survey_medley_step'                      = 'survey_medley_step',
  'survey_qualification'                    = 'survey_qualification',
  'survey_qualification_step'               = 'survey_qualification_step',
  'general_profile'                         = 'general_profile',
  'general_profile_step'                    = 'general_profile_step',
  'truncated_general_profile'               = 'truncated_general_profile',
  'truncated_general_profile_step'          = 'truncated_general_profile_step',
  'basic_profile'                           = 'basic_profile',
  'basic_profile_step'                      = 'basic_profile_step',
  'ste_widget'                              = 'ste_widget',
  'ste_third_rail_widget_purple'            = 'ste_third_rail_widget_purple',
  'ste_third_rail_widget_classic'           = 'ste_third_rail_widget_classic',
  'ste_learn_more'                          = 'ste_learn_more',
  'ste_banner'                              = 'ste_banner',
  'ste_popup_gp'                            = 'ste_popup_gp',
  'welcome_v3'                              = 'welcome_v3',
  'under_age'                               = 'under_age',
  'chat_bot'                                = 'chat_bot',
  'copy_link'                               = 'copy_link',
  'email'                                   = 'email',
  'fb_messenger'                            = 'fb_messenger',
  'fb'                                      = 'fb',
  'whatsapp'                                = 'whatsapp',
  'mobile_share'                            = 'mobile_share',
  'mobile_sms'                              = 'mobile_sms',
  'survey_junkie_home_page'                 = 'survey_junkie_home_page',
  'email_login'                             = 'email_login',
  'auto_email'                              = 'auto_email',
  'login_page'                              = 'login_page',
  'login_modal'                             = 'login_modal',
  'login_page_monolith'                     = 'login_page_monolith',
  'verify_device'                           = 'verify_device',
  'logout_manual'                           = 'logout_manual',
  'logout_auto'                             = 'logout_auto',
  'onfido'                                  = 'onfido',
  'ste_third_rail_widget'                   = 'ste_third_rail_widget',
  'ste_third_rail_widget_infeed'            = 'ste_third_rail_widget_infeed',
  'ste_third_rail_widget_learn_more'        = 'ste_third_rail_widget_learn_more',
  'ste_third_rail_widget_infeed_learn_more' = 'ste_third_rail_widget_infeed_learn_more',
  'ste_survey_complete'                     = 'ste_survey_complete',
  'ste_infeed_card'                         = 'ste_infeed_card',
  'none'                                    = 'none',
  'rvid'                                    = 'rvid',
  'fingerprint'                             = 'fingerprint',
  'paste'                                   = 'paste'
}

// Setup the analytics event attributes
enum ANALYTICS_EVENT_ATTRIBUTES {
  'a'                             = 'a',
  'aclid'                         = 'aclid',
  'activities'                    = 'activities',
  'activity_displayed_session_id' = 'activity_displayed_session_id',
  'anid'                          = 'anid',
  'app_region'                    = 'app_region',
  'application_platform'          = 'application_platform',
  'c'                             = 'c',
  'campaign'                      = 'campaign',
  'campaign_info_source'          = 'campaign_info_source',
  'can_redeem'                    = 'can_redeem',
  'click_id'                      = 'click_id',
  'click_location'                = 'click_location',
  'click_target'                  = 'click_target',
  'click_text'                    = 'click_text',
  'click_timestamp'               = 'click_timestamp',
  'click_url'                     = 'click_url',
  'content'                       = 'content',
  'cp1'                           = 'cp1',
  'currency'                      = 'currency',
  'current_question'              = 'current_question',
  'current_step'                  = 'current_step',
  'debug_mode'                    = 'debug_mode',
  'deferred_analytics_collection' = 'deferred_analytics_collection',
  'device_form_factor'            = 'device_form_factor',
  'email'                         = 'email',
  'engagement_time_msec'          = 'engagement_time_msec',
  'event_type'                    = 'event_type',
  'ga_session_id'                 = 'ga_session_id',
  'ga_session_number'             = 'ga_session_number',
  'gclid'                         = 'gclid',
  'has_prequalification'          = 'has_prequalification',
  'has_redeemed'                  = 'has_redeemed',
  'id'                            = 'id',
  'ignore_referrer'               = 'ignore_referrer',
  'index'                         = 'index',
  'is_first'                      = 'is_first',
  'item_brand'                    = 'item_brand',
  'item_category'                 = 'item_category',
  'item_category2'                = 'item_category2',
  'item_category3'                = 'item_category3',
  'item_category4'                = 'item_category4',
  'item_category5'                = 'item_category5',
  'item_id'                       = 'item_id',
  'item_list_id'                  = 'item_list_id',
  'item_list_name'                = 'item_list_name',
  'item_name'                     = 'item_name',
  'item_variant'                  = 'item_variant',
  'items'                         = 'items',
  'layout'                        = 'layout',
  'login_method'                  = 'login_method',
  'loi'                           = 'loi',
  'medium'                        = 'medium',
  'milestone'                     = 'milestone',
  'page_location'                 = 'page_location',
  'page_referrer'                 = 'page_referrer',
  'page_title'                    = 'page_title',
  'page_view_id'                  = 'page_view_id',
  'points'                        = 'points',
  'position'                      = 'position',
  'previous_first_open_count'     = 'previous_first_open_count',
  'previous_gmp_app_id'           = 'previous_gmp_app_id',
  'price'                         = 'price',
  'provider_id'                   = 'provider_id',
  'quantity'                      = 'quantity',
  'reset_analytics_cause'         = 'reset_analytics_cause',
  'response_code'                 = 'response_code',
  'response_message'              = 'response_message',
  'rid'                           = 'rid',
  's1'                            = 's1',
  's2'                            = 's2',
  's3'                            = 's3',
  's4'                            = 's4',
  's5'                            = 's5',
  'send_page_view'                = 'send_page_view',
  'sj_click_uuid'                 = 'sj_click_uuid',
  'sj_session_id'                 = 'sj_session_id',
  'source'                        = 'source',
  'step_event'                    = 'step_event',
  'step_name'                     = 'step_name',
  'style'                         = 'style',
  'sub_type'                      = 'sub_type',
  'survey_id'                     = 'survey_id',
  'survey_points'                 = 'survey_points',
  'survey_provider_id'            = 'survey_provider_id',
  'survey_count'                  = 'survey_count',
  'survey_tpa_count'              = 'survey_tpa_count',
  'survey_direct_count'           = 'survey_direct_count',
  'survey_usp_count'              = 'survey_usp_count',
  'survey_load_status'            = 'survey_load_status',
  'survey_status'                 = 'survey_status',
  'unaswered_count'               = 'unaswered_count',
  'stale_session'                 = 'stale_session',
  'system_app'                    = 'system_app',
  'system_app_update'             = 'system_app_update',
  'term'                          = 'term',
  'total_questions'               = 'total_questions',
  'total_steps'                   = 'total_steps',
  'type'                          = 'type',
  'updated_with_analytics'        = 'updated_with_analytics',
  'user_current_points'           = 'user_current_points',
  'user_id'                       = 'user_id',
  'user_pulse'                    = 'user_pulse',
  'user_signup_affiliate_id'      = 'user_signup_affiliate_id',
  'user_signup_source'            = 'user_signup_source',
  'user_status'                   = 'user_status',
  'user_total_points'             = 'user_total_points',
  'user_total_redemptions'        = 'user_total_redemptions',
  'verification_method'           = 'verification_method',
  'referrer'                      = 'referrer',
  'first_batch_survey_count'      = 'first_batch_survey_count',
  'first_batch_load_time'         = 'first_batch_load_time',
  'first_batch_provider_ids'      = 'first_batch_provider_ids',
  'total_survey_count'            = 'total_survey_count',
  'total_load_time'               = 'total_load_time',
  'total_provider_ids'            = 'total_provider_ids',
}

enum ANALYTICS_EVENT_SOURCE {
  'widget'           = 'widget',
  'modal'            = 'modal',
  'page'             = 'page',
  'dashboard'        = 'dashboard',
  'top_bar'          = 'top_bar',
  'account'          = 'account',
  'user'             = 'user',
  'missing_auth'     = 'missing_auth',
  'access_forbidden' = 'access_forbidden',
  'password_expired' = 'password_expired',
  'expired_token'    = 'expired_token',
  'fingerprintjs'    = 'fingerprintjs',
}

// Setup the analytics event names
enum ANALYTICS_EVENT_NAMES {
  'achievement'         = 'achievement',
  'click'               = 'click',
  'disable'             = 'disable',
  'enable'              = 'enable',
  'end'                 = 'end',
  'firebase_campaign'   = 'firebase_campaign',
  'first_open'          = 'first_open',
  'first_visit'         = 'first_visit',
  'gross_revenue'       = 'gross_revenue',
  'install'             = 'install',
  'login'               = 'login',
  'logout'              = 'logout',
  'net_revenue'         = 'net_revenue',
  'page_view'           = 'page_view',
  'redemption'          = 'redemption',
  'screen_view'         = 'screen_view',
  'session_start'       = 'session_start',
  'signup'              = 'signup',
  'start'               = 'start',
  'activity_view'       = 'activity_view',
  'activity_view_list'  = 'activity_view_list',
  'uninstall'           = 'uninstall',
  'user_engagement'     = 'user_engagement',
  'user_properties'     = 'user_properties',
  'view_item'           = 'view_item',
  'view_item_list'      = 'view_item_list',
  'view_search_results' = 'view_search_results',
  'gtm_config'          = 'gtm_config',
}

// Setup the analytics response code
enum ANALYTICS_RESPONSE_CODES {
  'success' = 200,
  'error'   = 400,
}

// Setup the STE analytics step names
enum ANALYTICS_STE_STEP_NAMES {
  'install_now'     = 'install_now',
  'install_android' = 'install_Android',
  'learn_more'      = 'learn_more',
  'cancel'          = 'cancel',
  'login'           = 'login',
}

enum ANALYTICS_STE_INSTRUCTIONS_STEP_NAMES {
  'intro_ext'        = 'intro_ext',
  'intro_app'        = 'intro_app',
  'benefits_ext'     = 'benefits_ext',
  'benefits_app'     = 'benefits_app',
  'how_it_works_ext' = 'how_it_works_ext',
  'how_it_works_app' = 'how_it_works_app',
  'thank_you_ext'    = 'thank_you_ext',
  'thank_you_app'    = 'thank_you_app',
}

// Setup the email analytics step names
enum ANALYTICS_EMAIL_STEP_NAMES {
  'email_survey_request' = 'email_survey_request',
  'email_window_closed'  = 'email_window_closed',
}

// Setup the analytics response message
enum ANALYTICS_RESPONSE_MESSAGE {
  'abandoned'           = 'abandoned',
  'success'             = 'success',
  'success_initialized' = 'success_initialized',
  'error'               = 'failure',
  'under_age'           = 'under_age',
  'confirm'             = 'confirm',
  'cancel'              = 'cancel',
  'fname'               = 'fname',
  'lname'               = 'lname',
  'zipcode'             = 'zipcode'
}

// Sign up types
enum ANALYTICS_LOGIN_TYPES {
  email           = 'email',
  social_google   = 'google',
  social_facebook = 'facebook',
}

enum ANALYTICS_LOGIN_ERROR_TYPES {
  'partial_registration' = 'partial_registration'
}

// Setup the analytics is_first
enum ANALYTICS_IS_FIRST {
  'zero' = 0,
  'one'  = 1,
}

// Setup the analytics redemption steps
enum ANALYTICS_BASIC_PROFILE_STEPS {
  'DOB'    = 1,
  'GENDER' = 2,
  'POSTAL' = 3,
}

enum ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE {
  'intro'              = 'intro',
  'zip_code'           = 'zip_code',
  'date_of_birth'      = 'date_of_birth',
  'gender'             = 'gender',
  'name_password'      = 'name_password'
}

enum ANALYTICS_ONFIDO_VERIFICATION_STEPS_VERBOSE {
  'review_info'  = 'review_info',
  'how_it_works' = 'how_it_works',
  'onfido_modal' = 'onfido_modal'
}

enum ANALYTICS_REFERRER_TYPES {
  'activity_history' = 'activity_history',
  'none'             = 'none',
}

enum ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES {
  'success'      = 'success',
  'save_error'   = 'save_error',
  'no_response'  = 'no_response',
  'load_error'   = 'load_error',
}

enum ANALYTICS_SURVEY_LOAD_STATUSES {
  'in_progress' = 'in_progress',
  'complete'    = 'complete',
}

export {
  ANALYTICS_BASIC_PROFILE_STEPS,
  ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE,
  ANALYTICS_BASIC_PROFILE_TYPES,
  ANALYTICS_COOKIE_NAMES,
  ANALYTICS_COOKIE_VALUE,
  ANALYTICS_EMAIL_STEP_NAMES,
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SOURCE,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
  ANALYTICS_IS_FIRST,
  ANALYTICS_LOGIN_ERROR_TYPES,
  ANALYTICS_LOGIN_TYPES,
  ANALYTICS_ONFIDO_VERIFICATION_STEPS_VERBOSE,
  ANALYTICS_REFERRER_TYPES,
  ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES,
  ANALYTICS_RESPONSE_CODES,
  ANALYTICS_RESPONSE_MESSAGE,
  ANALYTICS_STE_INSTRUCTIONS_STEP_NAMES,
  ANALYTICS_STE_STEP_NAMES,
  ANALYTICS_SURVEY_LOAD_STATUSES,
  ApplicationPlatformGA4
};
